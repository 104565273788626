import { createStore } from 'vuex'
import axios from 'axios'
import router from '../router'
// import md5 from 'js-md5'


export default createStore({
  state: {
    api: process.env.NODE_ENV !== 'development' ? window.location.origin + '/api' : process.env.VUE_APP_API_URL + '/api',
    files: process.env.NODE_ENV !== 'development' ? window.location.origin : process.env.VUE_APP_API_URL,
    chaturl: process.env.NODE_ENV !== 'development' ? process.env.VUE_APP_CHAT : process.env.VUE_APP_CHAT,
    mirrorWeb: '',
    logo: '',
    biglogo: '',
    trendHeader: '',
    opportunityHeader: '',
    newsHeader: '',
    date_end: null,

    urlretail: '',
    platform: '',
    chat_platform: '',

    tools: [],
    myaccountselected: false,
    selectedTool: null,
    userRegister: {},

    allData: {},

    languages: [],
    languageSelected: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
    translations: [],

    logged: false,
    privateplatform: false,
    privateexhibitor: null,
    products: { list: [], filter: {}, filterOptions: {}, exhibitor: {} },
    productData: {},
    exhibitors: { list: [], filter: {}, filterOptions: {} },
    exhibitorData: {},
    opportunities: {},
    opportunity: {},
    news: {},
    trends: {},
    trendItem: {},
    newsItem: {},
    myCategories: [],
    disabled_sections: [],
    errors: {
      login: [],
      regist: [],
      chat: false,
      calendar: null
    },
    errorApi: false,
    mesages: {
      login: [],
      calendar: []
    },
    myFavoritesData: {},
    cardsData: {},
    myfavoritesBack: {},
    cardsBack: {},
    card: {},
    exhibitorCards: [],

    call: false,
    callId: null,
    calldata: {},


    infoMessages: [],
    chatMessages: [],
    firstmessage: true,

    myCalendar: [],
    exhibitorCalendar: [],
    chat: {
      contacts: [],
      messages: []
    },
    contactStatus: '-',
    clickCard: '',
    refreshchat: null,
    refreshweb: null,
    calendarconfirm: false
  },
  mutations: {
    setConf(state, value) {
      state.files += value.directory_files
      state.logo = value.logo
      state.biglogo = value.biglogo
      state.newsHeader = value.news
      state.opportunityHeader = value.opportunity
      state.trendHeader = value.trend
      state.languages = value.languages
      state.tools = value.tools
      state.disabled_sections = value.sections
      state.urlretail = value.retail
      state.refreshweb = value.refreshweb
      state.refreshchat = value.refreshchat
      state.errorApi = value.maintenance
      state.chat_platform = value.chat_platform
      state.platform = value.platform
      state.mirrorWeb = value.apiUrl
      state.privateplatform = value.privateplatform
      state.privateexhibitor = value.privateexhibitor
      state.date_end = value.date_end
    },
    setTranslations(state, value) {
      state.translations = value
    },

    setUserRegister(state, value) {
      state.userRegister = value
    },
    setAllData(state, value) {
      state.allData = value
    },
    changeToolSelected(state, value) {
      state.selectedTool = value
      if (value === null) {
        state.mesages.login = []
      }
    },
    changeLoggedStatus(state, value) {
      state.logged = value

    },

    setError(state, value) {
      if ('login' in value) {
        state.errors.login.push(value.login)
      } else if ('regist' in value) {
        state.errors.regist.push(value.regist)
      }
      else if ('chat' in value) {
        state.errors.chat = value.chat
      } else if ('calendar' in value) {
        state.errors.calendar = value.calendar
      }
    },
    setMessage(state, value) {
      if (value.to == 'login') {
        state.mesages.login.push(value.msg)
      }
    },
    resetMessage(state, value) {
      state.mesages = {
        login: [],
        calendar: []
      }
    },

    setinfoMessages(state, value) {
      let position = null
      let i = 0
      if (state.infoMessages.length == 0) {
        state.infoMessages = []
      }
      if (state.chatMessages.length == 0) {
        state.chatMessages = []
      }

      switch (value.type) {
        case 'call':
          let found = state.infoMessages.some(msg => {
            if (msg.id === value.id) {
              position = i
            }
            i++
            return msg.id === value.id
          });

          if (!found) {
            state.infoMessages.push(value)
          } else {
            state.infoMessages[position] = value
            if (value.action && value.action == 'delete') {
              state.infoMessages.splice(value.index, 1)

            }
          }

          break;
        case 'chat':
          let foundchat = state.chatMessages.some(msg => {
            let result = false
            msg.alias.forEach(aliasback => {
              value.alias.forEach(alias => {
                if (alias.code === aliasback.code) {
                  position = i
                  result = true
                }

              });
            });
            i++
            return result
          });

          if (!foundchat) {
            state.chatMessages.push(value)

          } else {
            state.chatMessages[position] = value

          }
          state.firstmessage = false
          break;


      }




    },
    setCall(state, value) {
      if (value.type == 'delete') {
        state.infoMessages.splice(value.index, 1)
        if (state.infoMessages.length == 0) {
          state.call = false
        }
      } else if (value.type == 'show') {
        state.infoMessages[value.index]['show'] = true
        state.call = true
        state.callId = state.infoMessages[value.index].id
      }
    },
    resetCall(state, value) {
      state.call = false
      state.callId = null
    },
    setCallData(state, value) {

      state.calldata = value
    },
    setLanguage(state, value) {
      state.languageSelected = value
    },
    myAccountSelected(state, value) {
      state.myaccountselected = value
    },
    setMyCategories(state, value) {
      state.myCategories = value
      console.log(state.myCategories)

    },
    resetError(state, value) {
      if ('login' in value) {
        state.errors.login = []
      } else if ('regist' in value) {
        state.errors.regist = []

      } else if ('calendar' in value) {
        state.errors.calendar = null

      }
    },

    setProductList(state, value) {
      let products = {
        search: value.obj.search,
        products: value.obj.products
      }
      state.products = {
        list: products,
        filter: value.filter,
        filterOptions: value.filterOptions,
        type: value.type
      }
      if (value.obj.exhibitor) {
        state.products.exhibitor = value.obj.exhibitor
      }
    },
    resetProductList(state, value) {
      state.products = { list: [], filter: {}, filterOptions: {}, exhibitor: {} }

    },
    setExhibitorList(state, value) {
      state.exhibitors = {
        list: value.obj,
        filter: value.filter,
        filterOptions: value.filterOptions,
        type: value.type
      }
    },
    resetExhibitorList(state, value) {
      state.exhibitors = { list: [], filter: {}, filterOptions: {} }

    },
    setExhibitorData(state, value) {
      state.exhibitorData = value
    },
    setProductData(state, value) {
      state.productData = value
    },
    setOpportunities(state, value) {
      state.opportunities = {
        list: value.obj,
        filterOptions: value.filterOptions,
        type: value.type
      }
    },
    setOpportunity(state, value) {
      state.opportunity = value
    },
    setTrendItem(state, value) {
      state.trendItem = value
    },
    setNewsItem(state, value) {
      state.newsItem = value
    },
    setTrends(state, value) {
      state.trends = {
        list: value.obj,
        filterOptions: value.filterOptions,
        type: value.type
      }
    },
    setNews(state, value) {
      state.news = {
        list: value.obj,
        filterOptions: value.filterOptions,
        type: value.type
      }
    },
    setMyfavoritesBack(state, value) {
      state.myfavoritesBack = value
    },
    setCardsBack(state, value) {
      state.cardsBack = value
    },
    setFavorites(state, value) {
      state.myFavoritesData = value
    },
    setCards(state, value) {
      state.cardsData = value
    }, setCard(state, value) {
      state.card = value
    },
    setErrorApi(state, value) {
      state.errorApi = value
    },

    setMyCalendar(state, value) {
      state.myCalendar = value
    },
    setExhibitorCards(state, value) {
      state.exhibitorCards = value
    },
    setExhibitorCalendar(state, value) {
      state.exhibitorCalendar = value
    },
    setContacts(state, value) {
      state.chat.contacts = value.contacts
    },
    setMessages(state, value) {
      state.chat.messages = value

    },
    setClickCard(state, value) {
      state.clickCard = value
    },
    setCalendarConfirm(state, value) {
      state.calendarconfirm = value
    },
    changeContact(state, value) {
      state.contactStatus = value

    }
  },
  actions: {
    async setConf({ commit, state }) {
      var result = await axios.get(`${state.api}/conf.php`);
      let obj = await result.data;
      if (obj.title) {
        document.title = obj.title
        await commit('setConf', obj)

        if (obj.date_start) {

          var d1 = new Date();
          var d2 = new Date(obj.date_start);
          if (d1 < d2) {
            commit('setErrorApi', { date_start: obj.date_start, date_end: obj.date_end })
          }
        }

      }

    },
    async getTranslations({ commit, state }) {

      if (!['zh', 'en'].includes(state.languageSelected)) {
        await this.dispatch('selectLanguage', 'en')

      }
      let value = { language: state.languageSelected.toUpperCase() }
      let result = await axios({
        method: 'post',
        url: `${state.api}/translations.php`,
        data: value,
        transformRequest: function (obj) {
          var str = [];
          for (var p in obj)
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
          return str.join("&");
        },
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
      })

      let obj = await result.data;
      if (obj.status) {
        await commit('setTranslations', obj.data)

      }
    },
    selectLanguage({ commit }, id) {
      commit('setLanguage', id)
      localStorage.setItem('language', id)

    }, myAccountSelected({ commit }, value) {
      commit('myAccountSelected', value)

    },
    resetError({ commit }, obj) {
      commit('resetError', obj)
    },
    resetChangeContact({ commit }) {
      commit('changeContact', '-')
    },
    resetMessage({ commit }, obj) {
      commit('resetMessage', obj)
    },
    resetErrorApi({ commit }) {
      commit('setErrorApi', false)
    },
    async regist({ commit, state }, data) {
      commit('resetError', { regist: [] })
      if (data && state.languageSelected) {
        data.action = 'register'
        data.lang = state.languageSelected.toUpperCase().trim()

        console.log(data)
        let result = await axios({
          method: 'post',
          url: `${state.api}/session.php`,
          data: data,
          transformRequest: function (obj) {
            var str = [];
            for (var p in obj)
              str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            return str.join("&");
          },
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
        let obj = await result.data;

        if (obj.status) {
          obj.data.password = data.password
          localStorage.setItem('loged', JSON.stringify(obj.data))
          commit('changeLoggedStatus', true);
          if (data.picture) {
            await this.dispatch('uploadImage', { picture: data.picture, email: data.email, password: data.password })
          }
          commit('changeToolSelected', 10)
          await this.dispatch('myAccountSelected', true)
        } else if (obj.error) {

          commit('setError', { regist: obj.error })
        } else {

          commit('setError', { regist: 'error Regist' })
        }
      }
    },
    async uploadImage({ commit, state }, data) {
      let ls = localStorage.getItem('loged')
      if (state.logged && ls) {

        if (data) {
          let formData = new FormData();
          formData.append('picture', data.picture)
          formData.append('email', data.email)
          formData.append('password', data.password)
          formData.append('action', 'picture')
          let result = await axios({
            method: 'post',
            url: `${state.api}/session.php`,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
          });
          let obj = await result.data;
          if (obj.picture) {
            ls = ls ? JSON.parse(ls) : {}
            ls.picture = obj.picture
            localStorage.setItem('loged', JSON.stringify(ls))
          }
        }
      } else {
        commit('changeLoggedStatus', false)
        router.push('/')
      }

    },
    async uploadDownload({ commit, state }, data) {
      let ls = localStorage.getItem('loged')
      if (state.logged && ls) {

        if (data) {

          let formData = new FormData();
          formData.append('download', data.download)
          formData.append('email', data.email)
          formData.append('password', data.password)
          formData.append('action', 'download')
          let result = await axios({
            method: 'post',
            url: `${state.api}/session.php`,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
          });
          let obj = await result.data;
          if (obj.download) {
            ls = ls ? JSON.parse(ls) : {}
            ls.download = obj.download
            localStorage.setItem('loged', JSON.stringify(ls))
          }
        }
      } else {
        commit('changeLoggedStatus', false)
        router.push('/')
      }

    },
    async login({ commit, state, dispatch }, data) {
      commit('resetError', { login: [] })
      if (data) {
        data.action = 'login'
        try {

          let result = await axios({
            method: 'post',
            url: `${state.api}/session.php`,
            data: data,
            transformRequest: function (obj) {
              var str = [];
              for (var p in obj)
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
              return str.join("&");
            },
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
          })

          var obj = await result.data;


        } catch (error) {
          commit('setErrorApi', true)

        }
        if (!state.errorApi) {

          if (obj.status) {
            // if (obj.id %2 !== state.platform % 2 && !(!state.mirrorWeb || state.mirrorWeb.length === 0 || state.platform.length === 0)) {
            //   // console.log(obj.id)
            //   // await dispatch('logout')
            //   // console.log("desloged")
            //   // location.href = state.mirrorWeb
            // }else{
            console.log(obj)
            if (obj.data.email) {
              if (obj.data.lang) {
                let lang = localStorage.getItem('language') ? localStorage.getItem('language') : obj.data.lang.toLowerCase()
                this.dispatch('selectLanguage', lang)
                if (!['en', 'zh'].includes(state.languageSelected)) {
                  await this.dispatch('selectLanguage', 'en')
                }

              }
              if (obj.data.bookmarks) {
                let bookmarks = []
                obj.data.bookmarks.forEach(item => {
                  if (item.code) {
                    bookmarks.push(item)
                  }
                });

                localStorage.setItem('myFavorites', JSON.stringify({ items: bookmarks }))

              }

              commit('changeToolSelected', null)
              commit('changeLoggedStatus', true);
              obj.data.password = data.password
              localStorage.setItem('loged', JSON.stringify(obj.data))
              await this.dispatch('getMyCards')

            } else {
              if (localStorage.getItem('loged')) {
                localStorage.removeItem('loged');
              }
              commit('changeLoggedStatus', false);
              commit('changeToolSelected', 0)
              commit('setError', { login: 'Invalid email' })


            }
            // }
          } else {
            if (localStorage.getItem('loged')) {
              localStorage.removeItem('loged');
            }
            commit('changeLoggedStatus', false);

            commit('changeToolSelected', 0)
            let result = 'Server is under maintenance mode. Please try to log in later'
            if (obj.data) {
              result = obj.data

            }
            commit('setError', { login: result })
          }

        }


      }
    },

    async editCategories({ commit, state }, value) {
      let ls = JSON.parse(localStorage.getItem('loged'))

      if (ls) {
        let data = { email: ls.email, password: ls.password, action: 'editreceive', categories: JSON.stringify(value) }
        console.log(data)
        let result = await axios({
          method: 'post',
          url: `${state.api}/session.php`,
          data: data,
          transformRequest: function (obj) {
            var str = [];
            for (var p in obj)
              str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            return str.join("&");
          },
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
        let obj = await result.data;
        if (obj.status) {
          await this.dispatch('myAccountSelected', false)

        }
      }

    }, async getCategories({ commit, state }, value) {
      let ls = JSON.parse(localStorage.getItem('loged'))

      if (ls) {
        let data = { email: ls.email, password: ls.password, action: 'getreceive' }
        console.log(data)
        let result = await axios({
          method: 'post',
          url: `${state.api}/session.php`,
          data: data,
          transformRequest: function (obj) {
            var str = [];
            for (var p in obj)
              str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            return str.join("&");
          },
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
        let obj = await result.data;
        if (obj.status) {
          commit('setMyCategories', obj.data)


        }
      }

    },
    async recoverPassword({ commit, state }, value) {
      commit('resetError', { login: [] })
      commit('resetMessage')

      if (value) {

        let message = { email: value, action: 'recoverPassword', lang: state.languageSelected.toUpperCase() }
        let result = await axios({
          method: 'post',
          url: `${state.api}/validatemail.php`,
          data: message,
          transformRequest: function (obj) {
            var str = [];
            for (var p in obj)
              str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            return str.join("&");
          },
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
        let obj = await result.data;

        if (obj.status) {
          commit('setMessage', { msg: obj.data, to: 'login' });

        } else {
          commit('setError', { login: obj.data });

        }
      }

    },
    async changePassword({ commit, state }, value) {
      if (value) {
        value.action = 'changePassword'
        let result = await axios({
          method: 'post',
          url: `${state.api}/session.php`,
          data: value,
          transformRequest: function (obj) {
            var str = [];
            for (var p in obj)
              str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            return str.join("&");
          },
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
        let obj = await result.data;
        if (obj.status) {
          router.push('/')
          this.dispatch('login', { action: 'login', email: obj.data.email, password: value.password })

        }
      }

    },

    async editPasswordUser({ commit, state }, data) {
      if (data) {
        let result = await axios({
          method: 'post',
          url: `${state.api}/validatemail.php?action=${data.action}&token=${data.token}$lang=${state.languageSelected.toUpperCase()}`,
          data: data,
          transformRequest: function (obj) {
            var str = [];
            for (var p in obj)
              str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            return str.join("&");
          },
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })

        let obj = await result.data;
        if (obj.status) {
          router.push('/')

        }
      }
    },

    logout({ commit, state }) {
      commit('changeToolSelected', null)
      commit('changeLoggedStatus', false);
      localStorage.removeItem('loged');
      localStorage.removeItem('myFavorites');
      localStorage.removeItem('myCards');
      localStorage.removeItem('sendCards');
      localStorage.removeItem('chat');
      console.log("salir")
      router.push('/')

    },

    changeToolSelected({ commit, state, dispatch }, value) {
      let ls = JSON.parse(localStorage.getItem('loged'))
      let notvalidLogged = [0, 1]

      if (value == 14 || state.logged && notvalidLogged.includes(value)) {
        dispatch('logout')
      } else {

        commit('changeToolSelected', value)
      }
    },

    async getProducts({ commit, state }, data) {
      if (data && state.languageSelected) {

        data.lang = state.languageSelected.toUpperCase()

        let result = await axios({
          method: 'post',
          url: `${state.api}/searchproduct.php`,
          data: data,
          transformRequest: function (obj) {
            var str = [];
            for (var p in obj)
              str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            return str.join("&");
          },
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
        let obj = await result.data;
        let objRes = { search: obj.search, products: [], exhibitor: {} }
        let filterOptions = { category: [], type: [], year: [], operation: [] }

        if (obj.products && obj.products.length > 0) {
          await obj.products.forEach(element => {
            if (element.id) {
              objRes.products.push(element)

              if (!filterOptions.category.some(item => item.value == element.category)) {
                filterOptions.category.push({ value: element.category, text: element.category })
              }
              if (!filterOptions.type.includes(element.type)) {
                filterOptions.type.push(element.type)
              }
              if (!filterOptions.year.includes(element.market_entry) && element.market_entry != 0) {
                filterOptions.year.push(element.market_entry)
              }
              if (element.operation) {

                element.operation.forEach(elementoperation => {

                  if (!filterOptions.operation.includes(elementoperation)) {
                    filterOptions.operation.push(elementoperation)
                  }
                });
              }
            }
          });
        } else {
          await router.push('/')

        }
        if (obj.exhibitor) {
          objRes.exhibitor = obj.exhibitor[0]
        }
        if (filterOptions.year) {
          filterOptions.year.sort((a, b) => a - b)
        }

        await localStorage.setItem('product', JSON.stringify(data))
        await commit('setProductList', { obj: objRes, filter: data, filterOptions: filterOptions, type: 'products' })

      }
    },
    async getExhibitors({ commit, state }, data) {
      if (data && state.languageSelected) {
        console.log(data)
        let result = await axios({
          method: 'post',
          url: `${state.api}/searchexhibitor.php?lang=${state.languageSelected.toUpperCase()}`,
          data: data,
          transformRequest: function (obj) {
            var str = [];
            for (var p in obj)
              str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            return str.join("&");
          },
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
        let obj = await result.data;
        let objRes = { search: obj.search, exhibitors: [] }
        let filterOptions = { category: [], type: [], operation: [] }
        if (obj.exhibitors) {
          obj.exhibitors.forEach(element => {

            if (element.id) {
              objRes.exhibitors.push(element)
              element.categories.forEach(categoryelement => {
                if (categoryelement.name) {
                  if (!filterOptions.category.some(item => item.value == categoryelement.name)) {
                    filterOptions.category.push({ value: categoryelement.name, text: categoryelement.name })
                  }
                }

              });
              if (!filterOptions.type.includes(element.type)) {
                filterOptions.type.push(element.type)
              }

              if (element.operation) {

                element.operation.forEach(elementoperation => {

                  if (!filterOptions.operation.includes(elementoperation)) {
                    filterOptions.operation.push(elementoperation)
                  }
                });
              }
            }
          });

        }
        await localStorage.setItem('exhibitor', JSON.stringify(data))
        await commit('setExhibitorList', { obj: objRes, filter: data, filterOptions: filterOptions, type: 'exhibitors' })

      }
    },
    async resetExhibitorList({ commit, state }) {
      await commit('resetExhibitorList')

    },
    async resetProductList({ commit, state }) {
      await commit('resetProductList')

    },
    async resetExhibitor({ commit, state }) {
      await commit('setExhibitorData', {})

    }, async resetOpportunity({ commit, state }) {
      await commit('setOpportunity', {})

    },
    async getExhibitorData({ commit, state }, code) {
      if (code) {
        try {
          let data = {
            exhibid: code,
            lang: state.languageSelected.toUpperCase()
          }
          var result = await axios({
            method: 'post',
            url: `${state.api}/exhibitor.php`,
            data: data,
            transformRequest: function (obj) {
              var str = [];
              for (var p in obj)
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
              return str.join("&");
            },
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
          })
        } catch (err) {
          console.log(err)
        }

        let obj = await result.data.data

        if (obj && obj.brand) {

          obj.code = code
          let brandLookingFor = []
          if (obj.brandLookingFor.length > 0) {
            obj.brandLookingFor.forEach(element => {
              if (element.icon) {
                brandLookingFor.push(element)
              }
            });
            obj.brandLookingFor = brandLookingFor
          }
          let brandAwards = []
          if (obj.brandAwards.length > 0) {
            obj.brandAwards.forEach(element => {
              if (element.img) {
                brandAwards.push(element)
              }
            });
            obj.brandAwards = brandAwards
          }
          let consumerProduct = []
          if (obj.consumerProduct.length > 0) {
            obj.consumerProduct.forEach(element => {
              if (element.cid) {
                consumerProduct.push(element)
              }
            });
            obj.consumerProduct = consumerProduct
          }
          let businessProduct = []
          if (obj.businessProduct.length > 0) {
            obj.businessProduct.forEach(element => {
              if (element.cid) {
                businessProduct.push(element)
              }
            });
            obj.businessProduct = businessProduct
          }
          let downloads = []
          if (obj.downloads.length > 0) {
            obj.downloads.forEach(element => {
              if (element.code) {
                downloads.push(element)
              }
            });
            obj.downloads = downloads
          } let cards = []
          if (obj.cards.length > 0) {
            obj.cards.forEach(element => {
              if (element) {
                cards.push(element)
              }
            });
            obj.cards = cards
          }
          if (obj.brandWebsite) {
            obj.moreBrandInfo.website = { link: obj.brandWebsite, text: obj.brandWebsite.replace(/^https?\:\/\//i, "") }
          }


          commit('setErrorApi', false)
          await commit('setExhibitorData', obj)
        } else {
          router.push('/')

        }

      }
    },
    async resetProduct({ commit, state }) {
      await commit('setProductData', {})

    },
    async getProductData({ commit, state }, code) {
      if (code && state.languageSelected) {
        try {
          let data = {
            prodid: code,
            lang: state.languageSelected.toUpperCase()
          }
          var result = await axios({
            method: 'post',
            url: `${state.api}/product.php`,
            data: data,
            transformRequest: function (obj) {
              var str = [];
              for (var p in obj)
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
              return str.join("&");
            },
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
          })
        } catch (err) {
          console.log(err)
        }

        let obj = await result.data
        console.log(obj)
        if (obj && obj.brand) {
          obj.code = code
          let otherProducts = []
          if (obj.otherProducts.length > 0) {
            obj.otherProducts.forEach(element => {
              if (element.title) {
                otherProducts.push(element)
              }
            });
            obj.otherProducts = otherProducts
          }
          if (obj.moreProductInfo.manufacturer) {
            let value = obj.moreProductInfo.manufacturer
            obj.moreProductInfo.manufacturer = [{ name: value, url: obj.brandCode }]
          }
          let downloads = []
          if (obj.downloads.length > 0) {
            obj.downloads.forEach(element => {
              if (element.code) {
                downloads.push(element)
              }
            });
            obj.downloads = downloads
          }
          commit('setErrorApi', false)
          await commit('setProductData', obj)
        } else {
          router.push('/')


        }

      }
    },
    async getAllData({ commit, state }) {
      if (state.languageSelected) {

        try {
          let data = {
            lang: state.languageSelected.toUpperCase(),

          };

          var result = await axios({
            method: 'post',
            url: `${state.api}/homepage.php`,
            data: data,
            transformRequest: function (obj) {
              var str = [];
              for (var p in obj)
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
              return str.join("&");
            },
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
          })

          var obj = await result.data;
        } catch (err) {
          commit('setErrorApi', true)

          console.log(err)
        }
        if (!state.errorApi) {

          if (obj.status) {
            let categoriesComp = []
            if (obj.data.categoriesComp) {

              obj.data.categoriesComp.forEach(element => {
                if (element.title) {
                  categoriesComp.push(element)
                }
              });
              obj.data.categoriesComp = categoriesComp
            }

            let categoriesProd = []
            if (obj.data.categoriesProd) {
              obj.data.categoriesProd.forEach(element => {
                if (element.list) {
                  let list = []
                  element.list.forEach(elementlist => {
                    console.lo
                    if (elementlist.code) {
                      list.push(elementlist)
                    }
                  });
                  element.list = list
                  categoriesProd.push(element)
                }
                obj.data.categoriesProd = categoriesProd
              });
            }

            let exhibPromoted = []
            if (obj.data.exhibPromoted) {
              obj.data.exhibPromoted.forEach(element => {
                if (element.id) {
                  exhibPromoted.push(element)
                }
              });
              obj.data.exhibPromoted = exhibPromoted
            }
            let prodPromoted = []
            if (obj.data.prodPromoted) {
              obj.data.prodPromoted.forEach(element => {
                if (element.id) {
                  prodPromoted.push(element)
                }
              });
              obj.data.prodPromoted = prodPromoted
            }

            let presentations = []
            if (obj.data.presentations) {
              obj.data.presentations.forEach(element => {
                if (element.id) {
                  presentations.push(element)
                }
              })
              obj.data.presentations = presentations
            }

            let news = []
            if (obj.data.news) {
              obj.data.news.forEach(element => {
                if (element.id) {
                  news.push(element)
                }
              })
              obj.data.news = news
            }

            let sponsors = []
            if (obj.data.sponsors) {
              obj.data.sponsors.forEach(element => {
                if (element.id) {
                  sponsors.push(element)
                }
              })
              obj.data.sponsors = sponsors
            }
            let sections = []
            if (obj.data.sections) {
              obj.data.sections.forEach(element => {
                if (element.id) {
                  sections.push(element)
                }
              })
              obj.data.sections = sections
            }

            await commit('setAllData', obj.data)
          }
          else {
            commit('setErrorApi', obj.data)

          }
        }
      }
    },
    async getOpportunities({ commit, state }) {

      if (state.languageSelected) {

        let data = {
          lang: state.languageSelected.toUpperCase(),

        };
        let result = await axios({
          method: 'post',
          url: `${state.api}/searchopportunity.php`,
          data: data,
          transformRequest: function (obj) {
            var str = [];
            for (var p in obj)
              str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            return str.join("&");
          },
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
        let obj = await result.data;

        let opportunities = []
        let filterOptions = { type: [], public_sector: [] }

        if (obj.opportunities && obj.opportunities.length > 0) {

          obj.opportunities.forEach(element => {
            if (element.id) {
              opportunities.push(element)

              if (!filterOptions.type.includes(element.subtype)) {
                filterOptions.type.push(element.subtype)
              }
              if (!filterOptions.public_sector.includes(element.public_sector)) {
                filterOptions.public_sector.push(element.public_sector)
              }
            }
          });
          obj.opportunities = opportunities

        }
        await commit('setOpportunities', { obj: obj, filterOptions: filterOptions, type: 'opportunities' })
      }
    },
    async getOpportunity({ commit, state }, value) {

      if (state.languageSelected) {

        let data = {
          lang: state.languageSelected.toUpperCase(),
          code: value,
          action: 'get',
        }
        if (state.logged) {
          let ls = JSON.parse(localStorage.getItem('loged'))
          if (ls) {
            data.user_code = ls.code
          } else {
            commit('changeLoggedStatus', false)
            router.push('/')
          }
        }
        let result = await axios({
          method: 'post',
          url: `${state.api}/opportunity.php`,
          data: data,
          transformRequest: function (obj) {
            var str = [];
            for (var p in obj)
              str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            return str.join("&");
          },
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
        let obj = await result.data;

        if (obj.status) {
          commit('setOpportunity', obj.data);
        } else {
          router.push('/')
        }
      }
    },
    async saveOpportunity({ commit, state }, value) {

      let ls = JSON.parse(localStorage.getItem('loged'))
      if (state.logged && ls && state.languageSelected) {

        value.user_code = ls.code
        value.lang = state.languageSelected.toUpperCase()
        value.action = 'save'

        let result = await axios({
          method: 'post',
          url: `${state.api}/opportunity.php`,
          data: value,
          transformRequest: function (obj) {
            var str = [];
            for (var p in obj)
              str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            return str.join("&");
          },
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
        let obj = await result.data;

        if (obj.status) {
          router.push('/investment')
        }

      } else {
        commit('changeLoggedStatus', false)
        router.push('/')
      }
    },


    async getCallData({ commit, state }, value) {
      let ls = JSON.parse(localStorage.getItem('loged'))
      console.log(ls)
      if (ls) {
        let data = { meeting_code: value, email: ls.email, password: ls.password, action: 'get' }

        let result = await axios({
          method: 'post',
          url: `${state.api}/call.php`,
          data: data,
          transformRequest: function (obj) {
            var str = [];
            for (var p in obj)
              str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            return str.join("&");
          },
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
        let obj = await result.data;

        console.log(obj)

        if (obj.status) {
          await commit('setCallData', obj.data)
        }
      }
      else {
        commit('changeLoggedStatus', false)
        router.push('/')
      }

    },
    async getNews({ commit, state }) {

      if (state.languageSelected) {

        let data = {
          lang: state.languageSelected.toUpperCase(),

        };
        let result = await axios({
          method: 'post',
          url: `${state.api}/searchnews.php`,
          data: data,
          transformRequest: function (obj) {
            var str = [];
            for (var p in obj)
              str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            return str.join("&");
          },
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
        let obj = await result.data;

        let news = []
        let filterOptions = { type: [] }


        if (obj.news && obj.news.length > 0) {

          obj.news.forEach(element => {
            if (element.id) {
              news.push(element)

              if (element.subtype && !filterOptions.type.includes(element.subtype)) {
                filterOptions.type.push(element.subtype)
              }

            }
          });
          obj.news = news
          await commit('setNews', { obj: obj, filterOptions: filterOptions, type: 'news' })
        }
      }
    },
    async getTrends({ commit, state }) {

      if (state.languageSelected) {

        let data = {
          lang: state.languageSelected.toUpperCase(),

        };
        let result = await axios({
          method: 'post',
          url: `${state.api}/searchtrends.php`,
          data: data,
          transformRequest: function (obj) {
            var str = [];
            for (var p in obj)
              str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            return str.join("&");
          },
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
        let obj = await result.data;
        let trends = []
        let filterOptions = { type: [] }

        if (obj.trends && obj.trends.length > 0) {

          obj.trends.forEach(element => {
            if (element.id) {
              trends.push(element)
              if (element.subtype && !filterOptions.type.includes(element.subtype)) {
                filterOptions.type.push(element.subtype)
              }

            }
          });
          obj.trends = trends
          await commit('setTrends', { obj: obj, filterOptions: filterOptions, type: 'trends' })
        }
      }
    },
    async getTrendItem({ commit, state }, value) {
      let data = {
        lang: state.languageSelected.toUpperCase(),
        code: value
      }

      let result = await axios({
        method: 'post',
        url: `${state.api}/trend.php`,
        data: data,
        transformRequest: function (obj) {
          var str = [];
          for (var p in obj)
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
          return str.join("&");
        },
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
      })
      let obj = await result.data;

      if (obj.code) {
        commit('setTrendItem', obj);
      } else {
        router.push('/')

      }

    },
    resetTrend({ commit, state }) {
      commit('setTrendItem', {});

    },
    async checkMyFavorites({ commit, state }) {
      var logged = JSON.parse(localStorage.getItem('loged'))
      if (state.logged && logged) {
        var ls = JSON.parse(localStorage.getItem('myFavorites'))
        if (ls) {

          let lsItems = { products: [], exhibitors: [], opportunities: [], trends: [], news: [], download: [] }
          let position = 0

          if (ls.items) {

            ls.items.forEach(item => {

              switch (item.type) {
                case 'product':
                  lsItems.products.push({ code: item.code, position: position })
                  break;
                case 'exhibitor':
                  lsItems.exhibitors.push({ code: item.code, position: position })

                  break;
                case 'opportunity':
                  lsItems.opportunities.push({ code: item.code, position: position })

                  break;
                case 'trends':
                  lsItems.trends.push({ code: item.code, position: position })

                  break;
                case 'news':
                  lsItems.news.push({ code: item.code, position: position })

                  break;
                case 'download':
                  lsItems.download.push({ code: item.code, position: position })

                  break;

              }
              position++
            });
          }


          if (state.myfavoritesBack.email) {
            let back = state.myfavoritesBack.items

            if (!(back.products.length == lsItems.products.length &&
              back.exhibitors.length == lsItems.exhibitors.length &&
              back.opportunities.length == lsItems.opportunities.length &&
              back.trends.length == lsItems.trends.length &&
              back.news.length == lsItems.news.length &&
              back.download.length == lsItems.download.length
            )) {
              let data = { email: logged.email, password: logged.password, items: lsItems }

              let dataAction = { ...data }

              dataAction.action = 'bookmarks'

              dataAction.items = JSON.stringify(dataAction.items)

              console.log(dataAction)
              let result = await axios({
                method: 'post',
                url: `${state.api}/bookmarks.php`,
                data: dataAction,
                transformRequest: function (obj) {
                  var str = [];
                  for (var p in obj)
                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                  return str.join("&");
                },
                headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
              })
              let obj = await result.data;

              console.log(obj)
              if (obj.result) {
                commit('setMyfavoritesBack', data)
              }
            }
          } else {
            commit('setMyfavoritesBack', { email: logged.email, password: logged.password, items: lsItems })
          }
        }
      } else {
        if (state.logged) {
          commit('changeLoggedStatus', false)
          router.push('/')

        }
      }
    },

    async getMyCards({ commit, state }) {
      var ls = JSON.parse(localStorage.getItem('loged'))
      if (ls) {
        let data = { email: ls.email, password: ls.password, action: 'get' }

        let result = await axios({
          method: 'post',
          url: `${state.api}/cards.php`,
          data: data,
          transformRequest: function (obj) {
            var str = [];
            for (var p in obj)
              str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            return str.join("&");
          },
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
        let obj = await result.data;
        if (obj.status) {

          localStorage.setItem('myCards', JSON.stringify(obj.data))
        }
      } else {
        commit('changeLoggedStatus', false)
        router.push('/')
      }
    },
    async checkMyCards({ commit, state }, value) {
      var ls = JSON.parse(localStorage.getItem('loged'))
      if (state.logged && ls) {
        if (value.code && state.languageSelected) {

          let data = { email: ls.email, password: ls.password, cardid: value.code, action: value.action, lang: state.languageSelected.toUpperCase() }

          let result = await axios({
            method: 'post',
            url: `${state.api}/cards.php`,
            data: data,
            transformRequest: function (obj) {
              var str = [];
              for (var p in obj)
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
              return str.join("&");
            },
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
          })
          let obj = await result.data;

          console.log(obj)
        }

      } else {
        commit('changeLoggedStatus', false)
        router.push('/')
      }
    },


    async getFavorites({ commit, state }) {
      let ls = JSON.parse(localStorage.getItem('loged'))
      if (state.logged && ls) {
        let data = { email: ls.email, password: ls.password, action: 'list' }
        let result = await axios({
          method: 'post',
          url: `${state.api}/bookmarks.php`,
          data: data,
          transformRequest: function (obj) {
            var str = [];
            for (var p in obj)
              str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            return str.join("&");
          },
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
        let obj = await result.data;

        let filterOptions = { type: [] }

        if (obj.bookmarks) {
          let bookmarks = []
          obj.bookmarks.forEach(item => {
            if (item.code) {
              bookmarks.push(item)
              if (item.type && !filterOptions.type.includes(item.type)) {
                filterOptions.type.push(item.type)
              }
            }
          });

          bookmarks.type = 'favorite'
          bookmarks.filterOptions = filterOptions
          commit('setFavorites', bookmarks)
        }
      } else {
        commit('changeLoggedStatus', false)
        router.push('/')
      }
    },

    async getCards({ commit, state }) {
      let ls = JSON.parse(localStorage.getItem('loged'))
      if (state.logged && ls) {
        if (state.languageSelected) {

          let data = { email: ls.email, password: ls.password, action: 'list', lang: state.languageSelected.toUpperCase() }
          let result = await axios({
            method: 'post',
            url: `${state.api}/cards.php`,
            data: data,
            transformRequest: function (obj) {
              var str = [];
              for (var p in obj)
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
              return str.join("&");
            },
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
          })
          let obj = await result.data;
          if (obj.businesscards) {
            let businesscards = []
            obj.businesscards.forEach(item => {
              if (item.code) {
                businesscards.push(item)
              }
            });
            businesscards.type = 'card'
            commit('setCards', businesscards)
          }
        }

      } else {
        commit('changeLoggedStatus', false)
        router.push('/')
      }
    },
    setExhibitorCards({ commit }, cards) {
      console.log(cards)
      if (cards) {
        let cardsResult = []
        cards.forEach(element => {
          if (element) {
            cardsResult.push(element)
          }
        });
        commit('setExhibitorCards', cardsResult)
      }

    },
    async editMyInfo({ commit, state }, data) {
      let ls = localStorage.getItem('loged')
      console.log(data)
      if (state.logged && ls) {
        if (data) {
          data.action = 'update'
          let languages = data.languages
          data.languages = JSON.stringify(languages)
          console.log(data)
          let result = await axios({
            method: 'post',
            url: `${state.api}/session.php`,
            data: data,
            transformRequest: function (obj) {
              var str = [];
              for (var p in obj)
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
              return str.join("&");
            },
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
          })
          let obj = await result.data;
          console.log(obj)
          if (obj.return) {
            ls = ls ? JSON.parse(ls) : {}
            ls = {
              // business: data.business,
              code: data.code,
              email: data.email,
              fullName: data.fullname,
              lang: data.lang,
              languages: languages,
              option: data.option,
              password: data.password,
              picture: data.picture,
              position: data.position,
              timeOffset: data.timeOffset
            }
            localStorage.setItem('loged', JSON.stringify(ls))

            if (data.picture) {
              await this.dispatch('uploadImage', { picture: data.picture, email: data.email, password: data.password })
            }
            commit('changeToolSelected', null)

          }

        }

      } else {
        commit('changeLoggedStatus', false)
        router.push('/')
      }

    },

    setMessage({ commit }, value) {
      commit('setMessage', value)
    },

    setClickCard({ commit, state }, value) {

      let chat = null;
      if (state.chat && state.chat.contacts.length > 0) {
        state.chat.contacts.forEach(element => {
          if (element.code === value) {
            chat = element
          }
        });
        if (chat) {

          this.dispatch('getChat', chat)
          commit('setClickCard', chat.tchat)
        }
      }

    },

    async leaveCardTo({ commit, state }, value) {
      let ls = JSON.parse(localStorage.getItem('loged'))
      if (state.logged && ls) {
        if (state.languageSelected && value.cards[0]) {

          let data = { action: 'sendcard', cardid: value.cards[0], email: ls.email, password: ls.password, lang: state.languageSelected.toUpperCase() }
          console.log(value)

          let result = await axios({
            method: 'post',
            url: `${state.api}/cards.php`,
            data: data,
            transformRequest: function (obj) {
              var str = [];
              for (var p in obj)
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
              return str.join("&");
            },
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
          })
          let obj = await result.data;
          console.log(obj)
          if (obj.result) {
            var cards = localStorage.getItem('sendCards')
            cards = cards ? JSON.parse(cards) : { items: [] }
            let find = false
            if (cards.items.length > 0) {
              cards.items.forEach((element) => {

                if (element.code == data.cardid) {
                  find = true
                }
              })
            }
            if (!find) {
              cards.items.push({ code: data.cardid })
            }

            localStorage.setItem('sendCards', JSON.stringify(cards))
          }
        }

      } else {
        commit('changeLoggedStatus', false)
        router.push('/')
      }
    },

    async setCalendarCall({ commit, state }) {
      if (state.logged) {

        await this.dispatch('getMyCalendar')
        let mycal = state.myCalendar
        let date = new Date()
        let today = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + (date.getDate())).slice(-2)


        mycal.forEach(element => {
          let show = true


          if (element.id && element.day >= today) {
            let calendarDate = new Date(element.day + " " + element.startTime + ":00")
            let calendarDateEnd = new Date(element.day + " " + element.endTime + ":00")
            let action = ''
            if (date >= calendarDate && date <= calendarDateEnd) {

              action = 'incall'
            } else if (date <= calendarDate) {
              action = 'next'
            } else if (date >= calendarDateEnd) {
              action = 'delete'
              show = false
            }

            let msg = []
            if (action == 'next') {
              var num = (calendarDate - date) / 60000;
              var hours = (num / 60);
              var rhours = Math.floor(hours);
              var minutes = (hours - rhours) * 60;
              var rminutes = Math.round(minutes);
              if (rhours != 0) {
                msg.push(rhours)

              }
              msg.push(rminutes)
            } else {
              msg = element.title
            }

            let time = new Date(element.day + " " + element.startTime + ":00");
            let endTime = new Date(element.day + " " + element.endTime + ":00")

            commit('setinfoMessages', { id: element.id, title: state.translations.title_call, description: msg, startTime: time, endTime: endTime, type: 'call', show: show, action: action })
            this.dispatch('checkTime')

          }
        });

      }
    },
    checkTime({ commit, state }) {
      if (state.logged) {

        if (state.infoMessages.length > 0) {
          let date = new Date()
          let i = 0

          state.infoMessages.forEach(element => {
            let dateMessage = new Date(element.startTime.setMinutes(element.startTime.getMinutes() - 5));
            if (date > element.endTime) {
              commit('setCall', { index: i, type: 'delete' })
            } else if (date >= dateMessage && date < element.endTime
            ) {
              commit('setCall', { index: i, type: 'show' })

            }

            i++
          });
        }
      }
    },

    resetCall({ commit, state }) {
      commit('resetCall')
    },

    async getMyCalendar({ commit, state }) {
      let ls = JSON.parse(localStorage.getItem('loged'))
      if (state.logged && ls) {
        let data = { action: 'mycal', email: ls.email, password: ls.password, time_offset: ls.timeOffset }

        let result = await axios({
          method: 'post',
          url: `${state.api}/cal.php`,
          data: data,
          transformRequest: function (obj) {
            var str = [];
            for (var p in obj)
              str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            return str.join("&");
          },
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
        let obj = await result.data;
        if (obj.calendar && obj.calendar.length > 0) {
          let calendar = []
          await obj.calendar.forEach(element => {
            if (element.day) {
              let start = element.timeStart.split(':')
              let end = element.timeEnd.split(':')
              let item
              if (ls.option == 1 && element.status == 2) {
                item = {
                  id: element.meetingCode,
                  title: element.title,
                  day: element.day,
                  startTime: start[0] + ':' + start[1],
                  endTime: end[0] + ':' + end[1]
                }
              } else {

                item = {
                  id: element.meetingCode,
                  title: element.title,
                  day: element.day,
                  startTime: start[0] + ':' + start[1],
                  endTime: end[0] + ':' + end[1]
                }
              }

              calendar.push(item)
            }
          });
          commit('setMyCalendar', calendar)
        }
      } else {
        commit('changeLoggedStatus', false)
        router.push('/')
      }
    },
    async getExhibitorCalendar({ commit, state }, value) {
      let ls = JSON.parse(localStorage.getItem('loged'))
      if (state.logged && ls) {
        let data = { action: 'getcal', cardid: value, email: ls.email, password: ls.password, time_offset: ls.timeOffset }
        let result = await axios({
          method: 'post',
          url: `${state.api}/cal.php`,
          data: data,
          transformRequest: function (obj) {
            var str = [];
            for (var p in obj)
              str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            return str.join("&");
          },
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
        let obj = await result.data;
        let calendar = []
        if (obj.calendar) {
          obj.calendar.forEach(element => {
            if (element.day) {
              let start = element.timeStart.split(':')
              let end = element.timeEnd.split(':')

              let item = {
                id: element.meetingCode,
                day: element.day,
                startTime: start[0] + ':' + start[1],
                endTime: end[0] + ':' + end[1],
                status: element.status
              }
              calendar.push(item)
            }
          });
          obj.calendar = calendar
          commit('setExhibitorCalendar', obj);
        }
      } else {
        commit('changeLoggedStatus', false)
        router.push('/')
      }
    },
    setCalendarConfirm({ commit, state }, value) {
      commit('setCalendarConfirm', value)

    },
    async addToMyCalendar({ commit, state }, data) {
      let ls = JSON.parse(localStorage.getItem('loged'))
      this.dispatch('setCalendarConfirm', false)

      if (state.logged && ls) {
        data.email = ls.email
        data.password = ls.password
        data.action = 'request'
        data.time_offset = ls.timeOffset
        console.log(data)
        let result = await axios({
          method: 'post',
          url: `${state.api}/cal.php`,
          data: data,
          transformRequest: function (obj) {
            var str = [];
            for (var p in obj)
              str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            return str.join("&");
          },
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
        let obj = await result.data;
        if (obj.status) {

          this.dispatch('getExhibitorCalendar', data.cardid)
          this.dispatch('setCalendarCall')
          this.dispatch('setCalendarConfirm', obj.data)


        } else {
          commit('setError', { calendar: obj.data })

        }
      } else {
        commit('changeLoggedStatus', false)
        router.push('/')
      }
    },

    async getChat({ commit, state }, value) {
      let ls = JSON.parse(localStorage.getItem('loged'))
      if (state.logged && ls) {
        if (!state.errors.chat) {

          try {
            let data = { tuser: ls.code, token: value.tchat, platform: state.chat_platform }

            let result = await axios({
              method: 'post',
              url: `${state.chaturl}/messages/getMessages`,
              data: data,
              transformRequest: function (obj) {
                var str = [];
                for (var p in obj)
                  str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                return str.join("&");
              },
              headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
            })
            let obj = await result.data;

            let messages = []
            if (obj.status) {
              messages = obj.data.sort(function (b, a) {
                return new Date(b.date) - new Date(a.date);
              });

            }
            commit('setMessages', messages);
          } catch (error) {
            console.log(error)
            commit('setError', { chat: true });
          }
        }

      } else {
        commit('changeLoggedStatus', false)
        router.push('/')
      }
    },
    async getContacts({ commit, state }) {

      if (!state.errorApi) {

        let ls = JSON.parse(localStorage.getItem('loged'))
        if (state.logged && ls) {
          if (!state.errors.chat && state.languageSelected) {

            try {

              let data = {
                tuser: ls.code,
                platform: await state.chat_platform,
              }
              let result = await axios({
                method: 'post',
                url: `${state.chaturl}/getContacts`,
                data: data,
                transformRequest: function (obj) {
                  var str = [];
                  for (var p in obj)
                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                  return str.join("&");
                },
                headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
              })
              let obj = await result.data;

              if (obj.status && obj.data && Array.isArray(obj.data) && obj.data.length > 0) {
                let contacts = []
                let index = obj.data.length
                let count = 0
                await obj.data.forEach(async element => {
                  let datachat = {
                    cardid: element.tuser,
                    action: 'viewcard',
                    email: ls.email,
                    password: ls.password,
                    lang: state.languageSelected.toUpperCase()
                  }
                  let resultcard = await axios({
                    method: 'post',
                    url: `${state.api}/cards.php`,
                    data: datachat,
                    transformRequest: function (obj) {
                      var str = [];
                      for (var p in obj)
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                      return str.join("&");
                    },
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
                  })
                  let objcard = await resultcard.data;

                  if (objcard && element.token) {
                    objcard.tchat = element.token
                    let findindex = 0
                    obj.data.forEach(find => {
                      if (find.token == element.token) {
                        objcard.position = findindex

                      }
                      findindex++

                    });
                    contacts.push(objcard)
                  }
                  count++
                  if (count == index) {

                    await commit('setContacts', { contacts: contacts });
                  }
                });


              }
            } catch (error) {
              console.log(error)

              commit('setError', { chat: true });
            }
          }
        } else {
          if (state.logged) {

            commit('changeLoggedStatus', false)
            router.push('/')
          }
        }
      }

    },

    async sendMessage({ commit, state }, data) {
      let ls = JSON.parse(localStorage.getItem('loged'))
      if (state.logged && ls) {
        if (data && !state.errors.chat) {

          try {

            data.tuser = ls.code
            data.platform = state.chat_platform
            let result = await axios({
              method: 'post',
              url: `${state.chaturl}/messages/sendMessage`,
              data: data,
              transformRequest: function (obj) {
                var str = [];
                for (var p in obj)
                  str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                return str.join("&");
              },
              headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
            })
            let obj = await result.data;
            console.log(obj)
            if (obj.status) {
              await this.dispatch('getChat', { tchat: data.token })


            }
          } catch (error) {
            console.log(error)

            commit('setError', { chat: true });
          }
        }
      } else {
        commit('changeLoggedStatus', false)
        router.push('/')
      }
    },

    async addContact({ commit, state }, value) {
      let ls = JSON.parse(localStorage.getItem('loged'))
      if (state.logged && ls) {
        if (value && !state.errors.chat) {

          try {

            let data = {
              texhibitor: value,
              tuser: ls.code,
              platform: state.chat_platform,
              // tchat: md5(value + ls.code)
              // exhibitorName: 'Exhibitor ' + value.brand,
              // userName: ls.fullName,
            }
            let result = await axios({
              method: 'post',
              url: `${state.chaturl}/conversation/add`,
              data: data,
              transformRequest: function (obj) {
                var str = [];
                for (var p in obj)
                  str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                return str.join("&");
              },
              headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
            })
            let obj = await result.data;


            if (obj.status) {
              console.log(obj.data)
              await this.dispatch('getContacts')

              // commit('setCalendarContacts', { contacts: obj.data });

            }
          } catch (error) {
            console.log(error)

            commit('setError', { chat: true });
          }
        }

      } else {
        commit('changeLoggedStatus', false)
        router.push('/')
      }
    },
    async checkMessages({ commit, state }) {
      let ls = JSON.parse(localStorage.getItem('loged'))
      if (state.logged && ls) {

        if (!state.errors.chat && state.languageSelected) {
          try {

            let data = {
              tuser: ls.code,
              platform: state.chat_platform,

              // userName: ls.fullName
            }

            let result = await axios({
              method: 'post',
              url: `${state.chaturl}/user/getNMessages`,
              data: data,
              transformRequest: function (obj) {
                var str = [];
                for (var p in obj)
                  str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                return str.join("&");
              },
              headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
            })
            let obj = await result.data;

            // console.log(obj)

            if (obj.status) {

              if (localStorage.getItem('chat')) {
                let chatlist = JSON.parse(localStorage.getItem('chat'))
                let chatlistback = JSON.parse(localStorage.getItem('chatback'))
                let number = null
                let skip = false
                let alias = []

                if (!localStorage.getItem('chatback') || chatlist.chats.length <= 0) {

                  let data = {
                    chats: []
                  }

                  localStorage.setItem('chatback', JSON.stringify(data))
                }
                await chatlist.chats.forEach(element => {
                  obj.data.forEach(elementobj => {

                    if (elementobj.id == element.id) {
                      if (elementobj.n_message >= element.n_message) {

                        let num = elementobj.n_message - element.n_message
                        if (!number) {
                          number = 0
                        }
                        number += num
                        alias.push(elementobj)
                      } else if (elementobj.n_message < element.n_message) {
                        skip = true
                      }

                    }
                  });
                });

                if (number && number >= 0 && !skip) {

                  let msg = []

                  if (alias && alias.length == 1) {
                    let datachat = {
                      cardid: alias[0].code,
                      action: 'viewcard',
                      email: ls.email,
                      password: ls.password,
                      lang: state.languageSelected.toUpperCase()
                    }
                    let resultcard = await axios({
                      method: 'post',
                      url: `${state.api}/cards.php`,
                      data: datachat,
                      transformRequest: function (obj) {
                        var str = [];
                        for (var p in obj)
                          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        return str.join("&");
                      },
                      headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
                    })
                    let objcard = await resultcard.data;

                    let name = null
                    if (objcard) {
                      name = objcard.fullName
                    } else {
                      name = alias.length
                    }
                    msg = [number, name];
                    // msg = [number, name.charAt(0).toUpperCase() + name.slice(1)];
                  } else {
                    msg = [number, alias.length]

                  }
                  let time = new Date();
                  let endTime = new Date(time)

                  endTime.setMinutes(time.getMinutes() + 2)

                  let show = false

                  if (chatlistback && chatlistback.chats) {

                    chatlistback.chats.forEach((element) => {
                      alias.forEach((elementobj) => {
                        if (
                          elementobj.id == element.id &&
                          elementobj.n_message > element.n_message
                        ) {
                          show = true
                        }
                      })
                    })
                  }

                  if (state.firstmessage) {
                    show = true
                  }
                  commit('setinfoMessages', { title: state.translations.title_new_message, description: msg, startTime: time, endTime: endTime, type: 'chat', alias: alias, show: show })

                  ls.number = obj.data
                  let data = {
                    chats: obj.data
                  }
                  localStorage.setItem('chatback', JSON.stringify(data))
                } else {
                  let data = {
                    chats: obj.data
                  }

                  localStorage.setItem('chat', JSON.stringify(data))
                }
              } else {
                let data = {
                  chats: obj.data
                }

                localStorage.setItem('chat', JSON.stringify(data))
              }
              // commit('setCalendarContacts', { contacts: obj.data });
            }
          } catch (error) {
            console.log(error)

            commit('setError', { chat: true });
          }
        }

      } else {
        if (state.logged) {
          commit('changeLoggedStatus', false)
          router.push('/')

        }
      }
    },

    async getCardsInfo({ commit, state }, value) {
      let ls = JSON.parse(localStorage.getItem('loged'))
      if (state.logged && ls) {
        if (value && state.languageSelected) {

          let cards = []
          let cont = 0
          await value.forEach(async element => {
            if (element) {

              let data = {
                cardid: element,
                action: 'viewcard',
                email: ls.email,
                password: ls.password,
                lang: state.languageSelected.toUpperCase()
              }
              let result = await axios({
                method: 'post',
                url: `${state.api}/cards.php`,
                data: data,
                transformRequest: function (obj) {
                  var str = [];
                  for (var p in obj)
                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                  return str.join("&");
                },
                headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
              })
              let obj = await result.data;

              if (obj && element) {
                cards.push(obj)
              }
              cont++

              if (cont == value.length) {
                cards.type = 'cardexhibitor'
                await commit('setCards', cards);
              }
            }


          });
        }
      } else {
        commit('changeLoggedStatus', false)
        router.push('/')
      }

    }, async getCardCall({ commit, state }, value) {
      let ls = JSON.parse(localStorage.getItem('loged'))
      if (ls) {
        if (value) {

          let data = {
            cardid: value,
            action: 'viewcard',
            email: ls.email,
            password: ls.password,
            lang: state.languageSelected.toUpperCase()
          }
          let result = await axios({
            method: 'post',
            url: `${state.api}/cards.php`,
            data: data,
            transformRequest: function (obj) {
              var str = [];
              for (var p in obj)
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
              return str.join("&");
            },
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
          })
          let obj = await result.data;
          await commit('setCard', obj);

        }
      } else {
        commit('changeLoggedStatus', false)
        router.push('/')
      }

    },

    async sendContact({ commit, state }, value) {
      let ls = JSON.parse(localStorage.getItem('loged'))
      if (ls) {
        let data = {
          card: value.card,
          subject: value.subject,
          text: value.text,
          email: ls.email,
          password: ls.password,
          lang: state.languageSelected.toUpperCase()
        }
        let result = await axios({
          method: 'post',
          url: `${state.api}/contact.php`,
          data: data,
          transformRequest: function (obj) {
            var str = [];
            for (var p in obj)
              str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            return str.join("&");
          },
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
        let obj = await result.data;
        commit('changeContact', obj.status)
      } else {
        commit('changeLoggedStatus', false)
        router.push('/')
      }
    },
    saveActualNews({ commit, state }, data) {
      commit('setNewsItem', data);

    },



    async checkLocalStorage({ commit, state }) {
      this.dispatch('getAllData')
      if (localStorage.getItem('loged')) {
        let ls = JSON.parse(localStorage.getItem('loged'))
        let data = {
          email: ls.email,
          password: ls.password,

        }
        await this.dispatch('login', data)


      }

    }

  },
  modules: {
  }
})

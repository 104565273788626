<template>
  <div>
    <div id="nav">
      <Navbar />
    </div>
    <ApiError v-if="checkError()" />
    <Miniform :selected="selectedTool" v-if="checkTool()" />

    <router-view />
    <Footer v-if="!checkError()" />
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import Miniform from '@/components/Miniform.vue'
  import Navbar from '@/components/Navbar.vue'
  import Footer from '@/components/Footer.vue'
  import ApiError from '@/components/ApiError.vue'
  import router from './router'

  export default {
    components: {
      Navbar,
      Footer,
      Miniform,
      ApiError,
    },

    data() {
      return {
        timer: null,
      }
    },

    methods: {
      ...mapActions([
        'checkLocalStorage',
        'checkMyFavorites',
        'resetErrorApi',
        'setCalendarCall',
        // 'checkTime',
        'setConf',
        'checkMessages',
        'getContacts',
        'changeToolSelected',
        'getMyCards',
        'getTranslations',
      ]),
      checkTool() {
        let result = false
        if (this.selectedTool != null) {
          result = true
          document.body.style.overflow = 'hidden'
        } else if (this.selectedTool == null && !this.errorApi) {
          document.body.style.overflow = 'auto'
        }
        return result
      },
      checkError() {
        let result = false
        if (
          this.errorApi &&
          this.selectedTool != 1 &&
          this.selectedTool != 10
        ) {
          result = true
          document.body.style.overflow = 'hidden'
        } else if (this.selectedTool == null && !this.errorApi) {
          document.body.style.overflow = 'auto'
        }
        return result
      },
      notification() {
        let showfirst = false

        if (!('Notification' in window)) {
          console.log('This browser does not support system notifications')
        }
        // Comprobamos si ya nos habían dado permiso
        if (Notification.permission === 'granted') {
          // Si esta correcto lanzamos la notificación
          // console.log(this.chatMessages)
          this.chatMessages.forEach((element) => {
            // console.log(element)
            if (element.show) {
              if (this.selectedTool != 12) {
                let chatlist = JSON.parse(localStorage.getItem('chat'))
                let newmessage = false

                element.alias.forEach((alias) => {
                  chatlist.chats.forEach((elementobj) => {
                    if (
                      elementobj.id == alias.id &&
                      elementobj.n_message < alias.n_message
                    ) {
                      newmessage = true
                    }
                  })
                })
                if (newmessage) {
                  let now = new Date()

                  if (element.endTime >= now) {
                    //Definir descripcion
                    let result = ''

                    if (isNaN(element.description[1])) {
                      result = this.translations.txt_onenew_message
                      result = result.replace('{var0}', element.description[0])
                      result = result.replace('{var1}', element.description[1])
                    } else {
                      result = this.translations.txt_new_message
                      result = result.replace('{var0}', element.description[0])
                      result = result.replace('{var1}', element.description[1])
                    }

                    var options = {
                      body: result,
                      data: element.id,
                      icon: require('@/assets/logo.png'),
                    }
                    var n = new Notification(element.title, options)
                    let self = this
                    n.onclick = function(event) {
                      self.changeToolSelected(12)
                    }
                  }
                }
              }
              element.show = false
            }
          })
          this.infoMessages.forEach((element) => {
            if (element.show) {
              if (!showfirst) {
                showfirst = true
                if (this.$route.name != 'Call') {
                  let now = new Date()
                  let intime = element.startTime.setMinutes(
                    element.startTime.getMinutes() - 5
                  )

                  intime = new Date(intime)

                  if (intime <= now && element.endTime >= now) {
                    //Definir descripcion
                    let result = ''

                    if (
                      now >= element.startTime &&
                      now <= element.endTime &&
                      typeof element.description == 'string'
                    ) {
                      result = this.translations.txt_call_continue.replace(
                        '{var}',
                        element.description
                      )
                    } else if (now <= element.startTime) {
                      if (
                        element.description &&
                        element.description.length == 2
                      ) {
                        result = this.translations.txt_callstart_hm
                        result = result.replace(
                          '{var0}',
                          element.description[0]
                        )
                        result = result.replace(
                          '{var1}',
                          element.description[1]
                        )
                      } else {
                        result = this.translations.txt_callstart_m
                        result = result.replace('{var}', element.description[0])
                      }
                    }

                    var options = {
                      body: result,
                      data: element.id,
                      icon: require('@/assets/logo.png'),
                    }
                    var n = new Notification(element.title, options)
                    let self = this
                    n.onclick = function(event) {
                      router.push('/call/' + event.currentTarget.data)
                      self.changeToolSelected(null)
                    }
                    // setTimeout(n.close.bind(n), 5000)
                  }
                  element.show = false
                }
              }
            }
          })
        }

        // Si no, tendremos que pedir permiso al usuario
        else if (Notification.permission !== 'denied') {
          Notification.requestPermission(function(permission) {
            // Si el usuario acepta, lanzamos la notificación
            if (permission === 'granted') {
              var options = {
                body: 'Now you can receive notifications from the browser',
                icon: element.icon,
              }
              var n = new Notification('Thank you!', options)
              setTimeout(n.close.bind(n), 5000)
            }
          })
        }
      },
    },
    computed: {
      ...mapState([
        'selectedTool',
        'errorApi',
        'infoMessages',
        'chatMessages',
        'callId',
        'refreshweb',
        'translations',
      ]),
    },

    async created() {
      await this.getTranslations()
      // await this.setConf()

      await this.checkLocalStorage()
      await this.getContacts()
      await this.setCalendarCall()
      // await this.checkTime()
      await this.checkMyFavorites()
      // await this.getMyCards()
      await this.checkMessages()

      await this.notification()
    },
    async mounted() {
      if (!this.checkError()) {
        let num = 60
        if (
          this.refreshweb ||
          (this.refreshweb != 0 && this.refreshweb >= 30)
        ) {
          num = this.refreshweb
        }

        this.timer = setInterval(() => {
          // this.checkTime()
          this.checkMyFavorites()
          // if (!this.selectedTool) {
          this.getContacts()
          this.checkMessages()
          // }
          this.setCalendarCall()
          this.notification()
        }, num * 1000)
        // }, 10000)
      }
    },

    beforeUnmount() {
      clearInterval(this.timer)
    },
    watch: {
      $route(to, from) {
        this.resetErrorApi()
      },
    },
  }
</script>

<style>
  /* @import url('https://fonts.googleapis.com/css?family=Poppins'); */
  /* latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: local('Poppins'), url('/fonts/Poppins/Poppins.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }

  body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    user-select: none;
    min-width: 350px;
    overflow-y: scroll;
    scroll-snap-align: center;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  body::-webkit-scrollbar {
    display: none;
  }
  .mouseHover {
    cursor: pointer;
  }
  .stopscroll {
    overflow: hidden;
  }
  button {
    font-family: 'Poppins', sans-serif;
    background: none;
    border: 0;
    color: inherit;
    outline: none;
    cursor: pointer;
    height: 50px;
    font-size: 20px;
  }
  a {
    text-decoration: none;
  }
  .viewcontent {
    display: block;
    max-width: 1140px;
    width: 100%;
    margin: 10px auto 120px auto;
    text-align: center;
  }
  h1,
  h2 {
    font-weight: 500;
    margin: 5px;
    text-transform: capitalize;
  }
  p {
    margin: 0;
  }
  .btn,
  .toolform .btn {
    display: inline-block;
    margin: 10px 5px 10px 5px;
    padding: 6px;
    border: 1px;
    width: 20%;
    border-radius: 5px;
  }

  .buttonsform {
    position: fixed;
    bottom: 5px;
    left: 0;

    right: 0;
  }
  .btntool {
    display: inline-block;
    margin: 0 5px;
    padding: 6px;
    font-size: 15px;
    border: 1px;
    width: 20%;
    text-transform: lowercase;
    border-radius: 5px;
    vertical-align: top;
  }

  .showmore {
    text-align: center;
    text-transform: uppercase;
    border-radius: 50px;
    padding: 0 32px;
    margin: 25px 0 10px 0;
  }
  .descriptionform {
    padding: 10px 20px;
  }
  .description {
    margin: 0.5rem 0;
  }
  input:focus,
  select:focus {
    outline: none;
  }
  .toolform input,
  .toolform select,
  .toolform .inputfile {
    border-width: 3px;
    border-style: none none solid none;
    /* border-radius: 5px; */
    box-sizing: border-box;
    padding: 0 20px;
    width: 60%;
    margin: 10px;
    height: 50px;
    background-color: transparent;
  }

  ::placeholder,
  .toolform input,
  .toolform .inputfile label,
  .toolform select {
    font-size: 18px;
  }
  select:disabled {
    opacity: 1;
  }

  .toolform .inputfile {
    margin: 20px auto 10px auto;
    text-align: left;
    display: inline-block;
  }
  .toolform .inputfile label {
    float: left;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  .toolform .file {
    display: none;
  }

  label {
    width: 30%;
    text-align: left;
  }

  .message {
    width: 90%;
    margin: 0 auto;
    border-radius: 5px;
    font-weight: bold;
    padding: 10px;
  }

  @media screen and (max-width: 1000px) {
    .toolform input,
    .toolform select,
    .toolform .inputfile,
    .toolform .selector {
      width: 80%;
    }
  }
  @media screen and (max-width: 800px) {
    .viewcontent {
      margin-bottom: 140px;
    }
    .toolform input,
    .toolform select,
    .toolform .inputfile,
    .toolform .selector {
      width: 90%;
    }
    ::placeholder,
    .toolform input,
    .toolform .inputfile label,
    .toolform select,
    .multiselect-multiple-label,
    .multiselect-placeholder,
    .multiselect-single-label {
      font-size: 16px;
    }
  }
  @media screen and (max-width: 700px) {
    .viewcontent {
      margin-bottom: 190px;
    }
  }
  @media screen and (max-width: 600px) {
    .viewcontent {
      margin-bottom: 130px;
    }
    .btn,
    .btntool {
      font-size: 14px;
      width: 35%;
    }
  }
  @media screen and (max-width: 450px) {
    .viewcontent {
      margin-bottom: 130px;
    }
  }

  /* GLOBAL COLORS */
  .main_color {
    color: #6f6f6f;
  }
  .a_color {
    color: #201c1d;
  }
  .contrast_color {
    color: #fff;
  }
  .primary_color {
    color: #d25252;
  }
  .tertiary_color {
    color: #54595f;
  }
  .fourth_color {
    color: #303030;
  }
  .fifth_color {
    color: #7a7a7a;
  }
  .sixth_color {
    color: #a7a7a7;
  }
  .retail_color {
    color: #ff4a33;
  }
  .eighth_color {
    color: #e8e8e8;
  }
  .nineth_color {
    color: #e9e9e9;
  }
  .grey_contrast_color {
    color: #797979;
  }
  .black_color {
    color: #000;
  }
  .warning_color {
    color: #ffc107;
  }

  /* ------------- */
  .contrast_bg_color {
    background-color: #fff;
  }
  .primary_bg_color {
    background-color: #d25252;
  }
  .secundary_bg_color {
    background-color: #b3adad;
  }
  .tertiary_bg_color {
    background-color: #54595f;
  }
  .fourth_bg_color {
    background-color: #303030;
  }
  .fifth_bg_color {
    background-color: #7a7a7a;
  }
  .sixth_bg_color {
    background-color: #a7a7a7;
  }
  .seventh_bg_color {
    background-color: #fdfdfd;
  }
  .eighth_bg_color {
    background-color: #e8e8e8;
  }
  .calendar_bg_today {
    background-color: #d252524b;
  }
  .calendar_bg_available {
    background-color: #94ffb887;
  }
  .calendar_bg_notavailable {
    background-color: #bb4040;
  }
  .nineth_bg_color {
    background-color: #e9e9e9;
  }
  .retail_bg_color {
    background-color: #ededed;
  }
  .tenth_bg_color {
    background-color: #e6e5e5;
  }
  .eleventh_bg_color {
    background-color: #d5d5d5;
  }

  /* ------------- */
  .primary_bg_color_transparent {
    background-color: #d2525294;
  }
  .secundary_bg_color_transparent {
    background-color: #d2525294;
  }
  .contrast_bg_color_transparent {
    background-color: #ffffffb0;
  }
  .fourth_bg_color_transparent {
    background-color: #30303080;
  }
  .sixth_bg_color_transparent {
    background-color: #e4e1e175;
  }
  .seventh_bg_color_transparent {
    background-color: #fdfdfd9e;
  }
  .black_bg_transparent {
    background-color: #000000b3;
  }
  .chatright_bg_transparent {
    border-color: #d252522b;
    background-color: #d252522b;
  }
  .chatleft_bg_transparent {
    background-color: #a7a7a714;
    border-color: #a7a7a74a;
  }

  /* ------------- */
  .border_shadow {
    border-color: #d4d4d4;
  }

  .border_category {
    border-color: #54595f;
  }
  .border_primary {
    border-color: #d25252;
  }
  .border_fourth {
    border-color: #303030;
  }
  .border_sixth {
    border-color: #7a7a7a;
  }
  .border_tenth {
    border-color: #e6e5e5;
  }
  .border_calendar {
    border-color: #f5f3f3;
  }

  /* ------------- */
  .product:hover .productitem,
  .hover:hover {
    color: #d25252;
  }
  .product:hover .productitem .svgicon {
    /* primary_color https://codepen.io/sosuke/pen/Pjoqqp*/
    filter: invert(42%) sepia(31%) saturate(1346%) hue-rotate(313deg)
      brightness(93%) contrast(86%);
  }
  .homeback ul:hover li,
  .decorated:hover {
    color: #e5e5e5;
  }

  .slider .position.is-active,
  .slider .position.is-active:hover {
    background-color: #d25252;
  }

  .slider .position:hover {
    background-color: #7a7a7a;
  }
  .messagecont .right:before {
    border-color: #d25252;
  }
  .messagecont .left:before {
    border-color: #a7a7a7;
  }

  /* ------------- */
  .info_color {
    border: 1px solid #03a9f4;
    color: #03a9f4;
    background-color: #03a8f43a;
  }
  .error_color {
    border: 1px solid #d25252;
    color: #d25252;
    background-color: #d2525233;
  }
  #nav a.router-link-exact-active {
    background-color: #8b8b8b;
    color: #fff;
  }
  .slider .arrow svg {
    fill: #d25252;
    font-size: 10px;
  }

  .toolform .valid input {
    border-bottom-color: #03a9f4;
  }
  .toolform .invalid input {
    border-bottom-color: #ffc107;
  }
  .multiselect-tag i:before {
    color: #fff;
  }
  .multiselect-tag {
    background: #a7a7a7;
  }
  .multiselect-input {
    border-color: #54595f;
  }
  .multiselect-option.is-pointed {
    background: #fdfdfd;
  }
  .multiselect-multiple-label,
  .multiselect-placeholder,
  .multiselect-single-label {
    color: #54595f;
  }
  .multiselect-option:active {
    background-color: #a7a7a7;
  }
  .multiselect-caret {
    border-color: #54595f;
  }
  .is-disabled .multiselect-input {
    background-color: #fff;
  }
  .svgicon {
    /* .fifth_color https://codepen.io/sosuke/pen/Pjoqqp */
    filter: invert(53%) sepia(1%) saturate(427%) hue-rotate(18deg)
      brightness(89%) contrast(85%);
  }
  /* ------------- */
</style>
